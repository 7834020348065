.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 1%;
  align-items: center;

  .navlink {
    text-decoration: none;
    user-select: none;
    display: inline-block;
    max-width: max-content;

    &:link, &:visited, &:hover, &:active {
      color: black;
    }

    .dot-span {
      font-size: 40px;
      line-height: 0;
      color: #FF4100;
    }

    .logo {
      margin: 10px 0 0 10px;
      font-family: NunitoRegular, sans-serif;
      font-style: normal;
      font-size: 30px;
      cursor: pointer;
    }
  }

  .burger {
    margin: 2px 15px 0;
    padding: 0;
    font-family: NunitoRegular, sans-serif;
    font-style: normal;
    font-size: 14px;
    text-decoration: none;
    user-select: none;
    width: 20px;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    height: 20px;

    .burger-line {
      border: 1px solid black;
    }

    &:link, &:visited, &:hover, &:active {
      color: black;
    }
  }
}

@media only screen and (max-width: 600px) {
  .flex {
    .navlink {
      .logo {
        font-size: 7vw;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .flex {
    .burger {
      display: flex;
    }
  }
}
