.spacing {
    margin: 0 15px 0 15px;
    padding: 0;
    font-family: NunitoRegular, sans-serif;
    font-style: normal;
    font-size: 14px;
    text-decoration: none;
    user-select: none;
}

.spacing:link {
    color: black;
}

.spacing:visited {
    color: black;
}

.spacing:hover {
    color: black;
}

.spacing:active {
    color: black;
}

.visible {
    display: initial;
    border-bottom: 5px solid white;
}

.visible.item-active {
    border-bottom: 5px solid #FF4100;
}

@media only screen and (max-width: 700px) {
    .visible {
        display: none;
    }
}
