.fallback-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  p {
    font-family: NunitoRegular, sans-serif;
    font-size: 30px;

    &:after {
      content: '.';
      animation: dots 0.8s steps(5, end) infinite;
    }
  }
}

.fallback-loader {
  border: 12px solid #f3f3f3;
  border-top: 12px solid #FF4100;
  border-bottom: 12px solid #FF4100;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes dots {
  0%, 20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: .25em 0 0 rgba(0, 0, 0, 0),
    .5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: black;
    text-shadow: .25em 0 0 rgba(0, 0, 0, 0),
    .5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: .25em 0 0 black,
    .5em 0 0 rgba(0, 0, 0, 0);
  }
  80%, 100% {
    text-shadow: .25em 0 0 black,
    .5em 0 0 black;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
