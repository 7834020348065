@use "sass:color";

$orange: #FF4100;

.sidebar-outer-dark {
  position: absolute;
  visibility: hidden;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  z-index: 20;

  &.active {
    transition: unset;
    visibility: unset;
  }

  .sidebar-inner {
    width: 80%;
    transform: translate(-80%);
    height: 100%;
    background: white;
    transition: 100ms ease-in-out;
    display: flex;
    flex-direction: column;

    &.active {
      transform: translate(0);
    }

    .sidebar-inner-a {
      text-decoration: none;
      user-select: none;

      &:link, &:visited, &:hover, &:active {
        color: black;
      }
    }

    p {
      cursor: pointer;
      font-family: NunitoRegular, sans-serif;
      font-style: normal;
      font-size: 1.3rem;
      text-align: center;
      user-select: none;
      background-color: change-color(red, $alpha: 0.2);
      border-radius: 10px;
      padding-top: 10px;
      margin: 10px 10px;
      padding-bottom: 10px;
    }

    h1 {
      font-family: NunitoRegular, sans-serif;
      text-align: center;
      font-size: 3rem;
      line-height: 1;
      margin: 0.4em 0;
      user-select: none;
    }
  }

}
