.footer {
  background-color: #1D3354;
  color: white;
  padding: 30px 0;
  text-align: center;
  margin-top: 60px;

  .socials {
    display: grid;
    grid-template-columns: auto 20px 10px 20px auto;
    grid-template-rows: 20px;
    margin-bottom: 20px;

    img {
      height: 20px;
      width: 20px;
    }
  }

  .built-with-icon {
    margin-bottom: 5px;

    span:after {
      content: '⚛';
      font-size: 20px;
      display: inline-block;
      margin-left: 5px;
    }
  }

  p {
    margin: 0;
    font-family: LatoRegular, sans-serif;
    font-style: normal;
    font-size: 16px;

    a.refer-icons {
      &:link, &:visited, &:hover, &:active {
        color: #FF4100;
      }
    }

    code.footer-code {
      background-color: #53646d;
      color: white;
      padding: 4px;
      border-radius: 4px;
    }
  }
}
